// src/components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="bg-light text-center text-lg-start fixed-bottom">
      <div className="text-center p-1">
        © 2024 Fleet Sales System |{' '} 
        <a href="https://www.dahlhauser.net" target="_blank" rel="noopener noreferrer">
          Dahlhauser Solutions
        </a>
      </div>
    </footer>
  );
}

export default Footer;

