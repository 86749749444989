import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './Header.css';  // Custom CSS file for styles

function Header() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [role, setRole] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCodesDropdown, setShowCodesDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const codesDropdownRef = useRef(null);

  useEffect(() => {
    if (currentUser) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          setRole(idTokenResult.claims.role);
        }
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        codesDropdownRef.current &&
        !codesDropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
        setShowCodesDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  async function handleLogout() {
    try {
      await logout();
      navigate('/');
    } catch {
      console.error('Failed to log out');
    }
  }

  const handleAdminClick = () => {
    setShowDropdown(!showDropdown);
    setShowCodesDropdown(false);
  };

  const handleCodesClick = (e) => {
    e.stopPropagation();
    setShowCodesDropdown(!showCodesDropdown);
  };

  const handleAdminSelect = (section) => {
    navigate(`/admin?section=${section}`);
    setShowDropdown(false);
    setShowCodesDropdown(false);
  };

  // Helper function to determine if we are on an Admin-related route (including Codes)
  const isAdminRoute = () => location.pathname.startsWith('/admin');

  // Extract the section from the query params in the URL
  const getSectionName = () => {
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    if (!section) return '';
    // Capitalize the section name (you can adjust this logic as needed)
    if (section === 'salesids') {
      return 'Sales IDs'
    }
    if(section === 'colorCodes') {
      return 'Vehicle Colors'
    }
    if (section === 'eventCodes') {
      return 'Event Codes'
    }
    if (section === 'modelCodes') {
      return 'Model Codes'
    }
    return section.charAt(0).toUpperCase() + section.slice(1);
  };

  return (
    <Navbar bg="light" expand="lg" className="px-3">
      <Navbar.Brand as={Link} to="/" className="me-3">
        FSS
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {currentUser && (
            <>
              {(role === 'Admin' || role === 'Sales Manager' || role === 'Sales Rep') && (
                <>
                  <Nav.Link
                    as={Link}
                    to="/salesorders"
                    className={location.pathname === '/salesorders' ? 'active' : ''}
                  >
                    Sales Orders
                  </Nav.Link>
                </>
              )}
              {(role === 'Admin' || role === 'Sales Manager' || role === 'PDI') && (
                <>
                  <Nav.Link
                    as={Link}
                    to="/vehicles"
                    className={location.pathname === '/vehicles' ? 'active' : ''}
                  >
                    Vehicles
                  </Nav.Link>
                </>
              )}
              {(role === 'Admin' || role === 'Sales Manager' || role === 'BDC' || role === 'Sales Rep') && (
                <>
                  <NavDropdown
                    title="Admin"
                    id="admin-dropdown"
                    show={showDropdown}
                    onClick={handleAdminClick}
                    ref={dropdownRef}
                    className={isAdminRoute() ? 'admin-active' : ''}
                  >
                    {(role !== 'BDC') && (
                      <>
                        <NavDropdown.Item onClick={() => handleAdminSelect('customers')}>Customers</NavDropdown.Item>
                      </>
                    )}
                    {(role !== 'Sales Reps' && role !== 'BDC') && (
                      <>
                        <NavDropdown.Item onClick={() => handleAdminSelect('upfitters')}>Upfitters</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => handleAdminSelect('users')}>Users</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => handleAdminSelect('salesids')}>Sales IDs</NavDropdown.Item>
                      </>
                    )}
                    {(role === 'BDC') && (
                      <>
                        <NavDropdown.Item onClick={() => handleAdminSelect('users')}>Users</NavDropdown.Item>
                      </>
                    )}
                    {(role !== 'Sales Manager' && role !== 'BDC') && (
                      <NavDropdown
                        title="Codes"
                        id="codes-dropdown"
                        show={showCodesDropdown}
                        onClick={handleCodesClick}
                        className="dropend"
                        ref={codesDropdownRef}
                      >
                        <NavDropdown.Item onClick={() => handleAdminSelect('colorCodes')}>Vehicle Colors</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => handleAdminSelect('eventCodes')}>Event Codes</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => handleAdminSelect('modelCodes')}>Model Codes</NavDropdown.Item>
                      </NavDropdown>
                    )}
                  </NavDropdown>
                </>
              )}
              {role === 'Admin' && (
                <>
                  <Nav.Link
                    as={Link}
                    to="/dashboard"
                    className={location.pathname === '/dashboard' ? 'active' : ''}
                  >
                    Dashboard
                  </Nav.Link>
                </>
              )}
              {role === 'Customer' /*|| role === 'Admin'*/ && (
                <Nav.Link
                  as={Link}
                  to="/customer"
                  className={location.pathname === '/customer' ? 'active' : ''}
                >
                  Customer Portal
                </Nav.Link>
              )}
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
      {isAdminRoute() && (
        <div className="section-title">
          <h5 className="mb-0">{getSectionName()}</h5>
        </div>
      )}
      {currentUser && (
        <Navbar.Text className="ms-3">
          Signed in as: <a href="#login">{currentUser.email}</a>
        </Navbar.Text>
      )}
    </Navbar>
  );
}

export default Header;
