import React from 'react';
import { Container } from 'react-bootstrap';
import SalesOrderList from '../components/SalesOrderList';

function CustomerPortalPage() {

  return (
    <Container>
      <SalesOrderList
        isAdmin={false}
      />
    </Container>
  );
}

export default CustomerPortalPage;

