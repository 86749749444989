import React from 'react';
import { Container } from 'react-bootstrap';
import SalesOrderList from '../components/SalesOrderList';

function SalesOrderPage() {
  return (
    <Container>
      <SalesOrderList 
        isAdmin={true}
      />
    </Container>
  );
}

export default SalesOrderPage;
