import React, { useRef, useState, useEffect } from 'react';
import { useFirestore } from '../contexts/FirestoreContext';
import { Form, Button, Col, Row, FloatingLabel, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
//import { moveVehicleTeamsFolder } from '../utils/teamsUtils'; // Import the function

// Helper function to format numbers as currency
const formatCurrency = (value) => {
  // Remove any existing commas from the value before formatting
  if (isNaN(value) || value === null) return '';
  const numberValue = parseFloat(value.toString().replace(/,/g, '')); // Remove commas and parse
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(numberValue);
};

// Helper function to remove currency formatting (e.g., "$" and commas)
const parseCurrency = (value) => {
  if (!value) return 0;
  // Remove commas and any currency symbols like "$" then parse as a float
  return parseFloat(value.replace(/[$,]/g, '')) || 0;
};

function VehicleForm({ salesOrderNumber, onVehicleAdded, vehicle, onVehicleUpdated, onBulkEditSubmit, modelCodes, addVehicle=false }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [salesOrders, setSalesOrders] = useState([]);
  const [upfitterStatusOptions, setUpfitterStatusOptions] = useState([]);
  const [upfitters, setUpfitters] = useState([]);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState(salesOrderNumber || '');
  const [originalSalesOrder, setOriginalSalesOrder] = useState(salesOrderNumber || '');
  const { addData, updateData, getData, queryData } = useFirestore();
  const [submitMessage, setSubmitMessage] = useState("");
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [vehSalePrice, setVehSalePrice] = useState();
  const [vehMSRP, setVehMSRP] = useState();
  const [vehCOS, setVehCOS] = useState();
  const [rebate, setRebate] = useState();
  const [warrSale, setWarrSale] = useState();
  const [warrCost, setWarrCost] = useState();
  // Add additional state for tracking edit mode for each field
  const [editingVehSalePrice, setEditingVehSalePrice] = useState(false);
  const [editingVehMSRP, setEditingVehMSRP] = useState(false);
  const [editingVehCOS, setEditingVehCOS] = useState(false);
  const [editingRebate, setEditingRebate] = useState(false);
  const [editingWarrSale, setEditingWarrSale] = useState(false);
  const [editingWarrCost, setEditingWarrCost] = useState(false);
  const [editingTTFees, setEditingTTFees] = useState(false);
  const [editingVehIncent, setEditingVehIncent] = useState(false);
  const [editingDoas, setEditingDoas] = useState(false);
  const [editingAccess, setEditingAccess] = useState(false);
  const [ttFees, setTTFees] = useState(); // State for Tag and Title Fees
  const [vehIncent, setVehIncent] = useState();
  const [access, setAccess] = useState();
  const [doas, setDoas] = useState();
  const [vq2, setVQ2] = useState();
  const [taxes, setTaxes] = useState();
  const [transCost, setTransCost] = useState();
  const [upfitCost, setUpfitCost] = useState();
  const [editingVQ2, setEditingVQ2] = useState(false);
  const [editingTaxes, setEditingTaxes] = useState(false);
  const [editingTransCost, setEditingTransCost] = useState(false);
  const [editingUpfitCost, setEditingUpfitCost] = useState(false);
  const [manualStatus, setManualStatus] = useState('');
  const [manualETA, setManualETA] = useState(null);

  // Refs for all the fields
  const ordNbrRef = useRef();
  const danRef = useRef();
  const poNbrRef = useRef();
  const poLineNbrRef = useRef();
  const vinRef = useRef();
  const stockNbrRef = useRef();
  const upfitterNbrRef = useRef();
  const upfitterStatusRef = useRef();
  const upfitterETARef = useRef();
  const upfitterCompRef = useRef();
  const yearRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();
  const colorRef = useRef();
  const manStatRef = useRef();
  const dtManETARef = useRef();
  const dtProdDateRef = useRef();
  const dropIndRef = useRef();
  const recvDateRef = useRef();
  const dropLocRef = useRef();
  const delivStatRef = useRef();
  const delivDateRef = useRef();
  const delivETARef = useRef();
  const billCDKRef = useRef();
  const rptDateRef = useRef();
  const recallRef = useRef();
  const dealNbrRef = useRef();
  const saleDateRef = useRef();
  const vehSalePriceRef = useRef();
  const vehMSRPRef = useRef();
  const vehCOSRef = useRef();
  const rebateRef = useRef();
  const vehIncentRef = useRef();
  const warrSaleRef = useRef();
  const warrCostRef = useRef();
  const warrTypeRef = useRef();
  const doasRef = useRef();
  const keysRef = useRef();
  const accessRef = useRef();
  const vq2Ref = useRef();
  const taxesRef = useRef();
  const transCostRef = useRef();
  const upfitCostRef = useRef();
  const ttFeesRef = useRef();
  const billNotesRef = useRef();
  const statusDateRef = useRef();
  const dtPDIRef = useRef();
  const manualStatusRef = useRef();
  const manualETARef = useRef();

  // Track dirty fields
  const [dirtyFields, setDirtyFields] = useState({});
  

  // Fetch sales orders and sort them (newest to oldest, with "Stock" at the top)
  useEffect(() => {
    const fetchSalesOrders = async () => {
      try {
        // Query the salesOrders collection where strSalesOrdStatus is "Active"
        const activeSalesOrders = await queryData('salesOrders', 'strSalesOrdStatus', '==', 'Active');


        // Sort sales orders by descending order of the sales order number (YYMMDD-XX format)
        const sortedSalesOrders = activeSalesOrders.sort((a, b) => {
          // If either of the sales orders is "Stock", prioritize it at the top
          if (a.strSalesOrdNbr === 'Stock') return -1;
          if (b.strSalesOrdNbr === 'Stock') return 1;

          // Extract date and XX portion from the sales order numbers
          const dateA = new Date(`20${a.strSalesOrdNbr.slice(0, 2)}-${a.strSalesOrdNbr.slice(2, 4)}-${a.strSalesOrdNbr.slice(4, 6)}`);
          const dateB = new Date(`20${b.strSalesOrdNbr.slice(0, 2)}-${b.strSalesOrdNbr.slice(2, 4)}-${b.strSalesOrdNbr.slice(4, 6)}`);

          // First compare by date (newest to oldest)
          if (dateA.getTime() !== dateB.getTime()) {
            return dateB - dateA;
          }

          // If dates are the same, compare by the XX part (as numbers, not strings)
          const xxA = parseInt(a.strSalesOrdNbr.slice(7), 10);
          const xxB = parseInt(b.strSalesOrdNbr.slice(7), 10);

          return xxB - xxA; // Sort from highest XX to lowest XX
        });


        setSalesOrders(sortedSalesOrders);
      } catch (error) {
        console.error('Error fetching active sales orders:', error);
      }
    };

    fetchSalesOrders();
  }, [queryData]);

  useEffect(() => {
    if (addVehicle) {
      console.log("Running Sales Order Number set dirty")
      setDirtyFields((prevState) => ({ ...prevState, strSalesOrdNbr: true }));
    }
  }, [salesOrderNumber]);

  // Fetch upfitters and upfitter status options
  useEffect(() => {
    const fetchUpfitters = async () => {
      try {
        const upfittersData = await getData('Upfitters');
        setUpfitters(upfittersData);
      } catch (error) {
        console.error('Error fetching upfitters:', error);
      }
    };

    const fetchUpfitterStatusOptions = async () => {
      try {
        const upfitterStatusData = await queryData('eventCodes', 'strFactory', '==', 'UPFITTER');
        setUpfitterStatusOptions(upfitterStatusData);
      } catch (error) {
        console.error('Error fetching upfitter status options:', error);
      }
    };

    fetchUpfitters();
    fetchUpfitterStatusOptions();
  }, [getData, queryData]);

  useEffect(() => {
    if (vehicle && vehicle.length === 1) {
      const v = vehicle[0];
      if (manualStatusRef.current) manualStatusRef.current.value = v.manualStatus || '';
      if (manualETARef.current) manualETARef.current.value = v.manualETA ? v.manualETA.toDate().toISOString().split('T')[0] : '';
    }
  }, [vehicle]);


  useEffect(() => {
    if (vehicle && vehicle.length === 1) {
      const v = vehicle[0];
      if (ordNbrRef.current) ordNbrRef.current.value = v.strOrdNbr || '';
      setSelectedSalesOrder(v.strSalesOrdNbr || '');
      setOriginalSalesOrder(v.strSalesOrdNbr || '');
      if (danRef.current) danRef.current.value = v.nDAN || '';
      if (poNbrRef.current) poNbrRef.current.value = v.strPONbr || '';
      if (poLineNbrRef.current) poLineNbrRef.current.value = v.strPOLineNbr || '';
      if (vinRef.current) vinRef.current.value = v.strVin || '';
      if (stockNbrRef.current) stockNbrRef.current.value = v.strStockNbr || '';
      if (yearRef.current) yearRef.current.value = v.strYR || '';
      if (makeRef.current) makeRef.current.value = v.strMake || '';
      if (modelRef.current) modelRef.current.value = v.strModel || '';
      if (colorRef.current) colorRef.current.value = v.strColor || '';
      if (manStatRef.current) manStatRef.current.value = v.strManStat || '';
      if (dtManETARef.current) dtManETARef.current.value = v.dtManETA ? v.dtManETA.toDate().toISOString().split('T')[0] : '';
      if (dtProdDateRef.current) dtProdDateRef.current.value = v.dtProdDate ? v.dtProdDate.toDate().toISOString().split('T')[0] : '';
      if (dropIndRef.current) dropIndRef.current.checked = v.bDropInd || false;
      if (recvDateRef.current) recvDateRef.current.value = v.dtRecvDate ? v.dtRecvDate.toDate().toISOString().split('T')[0] : '';
      if (dropLocRef.current) dropLocRef.current.value = v.strDropLoc || '';
      if (delivStatRef.current) delivStatRef.current.value = v.strDelivStat || '';
      if (delivDateRef.current) delivDateRef.current.value = v.dtDelivDate ? v.dtDelivDate.toDate().toISOString().split('T')[0] : '';
      if (delivETARef.current) delivETARef.current.value = v.dtDelivETA ? v.dtDelivETA.toDate().toISOString().split('T')[0] : '';
      if (billCDKRef.current) billCDKRef.current.checked = v.bBillCDK || false;
      if (rptDateRef.current) rptDateRef.current.value = v.dtRptDate ? v.dtRptDate.toDate().toISOString().split('T')[0] : '';
      if (recallRef.current) recallRef.current.checked = v.bRecall || false;
      if (dealNbrRef.current) dealNbrRef.current.value = v.strDealNbr || '';
      if (saleDateRef.current) saleDateRef.current.value = v.dtSaleDate ? v.dtSaleDate.toDate().toISOString().split('T')[0] : '';
      if (vehSalePriceRef.current) vehSalePriceRef.current.value = v.curVehSalePrice || '';
      if (vehMSRPRef.current) vehMSRPRef.current.value = v.curVehMSRP || '';
      if (vehCOSRef.current) vehCOSRef.current.value = v.curVehCOS || '';
      if (rebateRef.current) rebateRef.current.value = v.curRebate || '';
      if (vehIncentRef.current) vehIncentRef.current.value = v.curVehIncent || '';
      if (warrSaleRef.current) warrSaleRef.current.value = v.curWarrSale || '';
      if (warrCostRef.current) warrCostRef.current.value = v.curWarrCost || '';
      if (warrTypeRef.current) warrTypeRef.current.value = v.strWarrType || '';
      if (doasRef.current) doasRef.current.value = v.curDoas || '';
      if (keysRef.current) keysRef.current.value = v.curKeys || '';
      if (accessRef.current) accessRef.current.value = v.currAccess || '';
      if (vq2Ref.current) vq2Ref.current.value = v.curVQ2 || '';
      if (taxesRef.current) taxesRef.current.value = v.curTaxes || '';
      if (upfitCostRef.current) upfitCostRef.current.value = v.curUpfitCost || '';
      if (transCostRef.current) transCostRef.current.value = v.curTransCost || '';
      if (ttFeesRef.current) ttFeesRef.current.value = v.curTTFees || '';
      if (billNotesRef.current) billNotesRef.current.value = v.strBillNotes || '';
      if (statusDateRef.current) statusDateRef.current.value = v.statusDate ? v.statusDate.toDate().toISOString().split('T')[0] : '';
      if (dtPDIRef.current) dtPDIRef.current.value = v.dtPDI ? v.dtPDI.toDate().toISOString().split('T')[0] : '';
    }
  }, [vehicle]);

  // Update values when the component is mounted or when vehicle data changes
  useEffect(() => {
    if (vehicle && vehicle.length === 1) {
      const v = vehicle[0];
      setVehSalePrice(formatCurrency(v.curVehSalePrice || 0));
      setVehMSRP(formatCurrency(v.curVehMSRP || 0));
      setVehCOS(formatCurrency(v.curVehCOS || 0));
      setRebate(formatCurrency(v.curRebate || 0));
      setWarrSale(formatCurrency(v.curWarrSale || 0));
      setWarrCost(formatCurrency(v.curWarrCost || 0));
      setVQ2(formatCurrency(v.curVQ2 || 0));
      setTaxes(formatCurrency(v.curTaxes || 0));
      setUpfitCost(formatCurrency(v.curUpfitCost || 0));
      setTransCost(formatCurrency(v.curTransCost || 0));
      setTTFees(formatCurrency(v.curTTFees || 0));
      setVehIncent(formatCurrency(v.curVehIncent || 0));
      setAccess(formatCurrency(v.currAccess || 0));
      setDoas(formatCurrency(v.curDoas || 0));
    }
  }, [vehicle]);

  useEffect(() => {
    if (vehicle && vehicle.length === 1) {
      const v = vehicle[0];
      console.log("Vehilce V: ", v)
    }
  }, [vehicle]);

  useEffect(() => {
    if (vehicle && vehicle.length === 1 && upfitters.length > 0 && upfitterStatusOptions.length > 0) {
      const v = vehicle[0];
      if (upfitterNbrRef.current) upfitterNbrRef.current.value = v.strUpfitterNbr || '';
      if (upfitterStatusRef.current) upfitterStatusRef.current.value = v.strUpfitterStatus || '';
      if (upfitterETARef.current) upfitterETARef.current.value = v.dtUpfitterETA ? v.dtUpfitterETA.toDate().toISOString().split('T')[0] : '';
      if (upfitterCompRef.current) upfitterCompRef.current.value = v.dtUpfitterComp ? v.dtUpfitterComp.toDate().toISOString().split('T')[0] : '';
    }
  }, [vehicle, upfitters, upfitterStatusOptions]);

  // Helper to mark fields as dirty
  const handleFieldChange = (field, value) => {
    setDirtyFields((prev) => ({ ...prev, [field]: true }));
  };

  const handleSalesOrderChange = (e) => {
    const selectedOrder = e.target.value;
    setSelectedSalesOrder(selectedOrder);

    // Mark the sales order field as dirty
    setDirtyFields((prevState) => ({ ...prevState, strSalesOrdNbr: true }));
  };


  const handleUpfitterChange = (e) => {
    console.log("Event: ", e)
    console.log("upfitterNbrRef: ", upfitterNbrRef)
    if (upfitterNbrRef.current) {
      upfitterNbrRef.current.value = e.target.value;
    }
  };

  const handleUpfitterStatusChange = (e) => {
    if (upfitterStatusRef.current) {
      upfitterStatusRef.current.value = e.target.value;
    }
  };

  const handleUpfitterETAChange = (e) => {
    if (upfitterStatusRef.current) {
      upfitterStatusRef.current.value = e.target.value;
    }
  };

  const handleUpfitterCompChange = (e) => {
    if (upfitterStatusRef.current) {
      upfitterStatusRef.current.value = e.target.value;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const updatedVehicle = {
      strOrdNbr: ordNbrRef.current?.value || '',
      strSalesOrdNbr: selectedSalesOrder,
      nDAN: danRef.current?.value || '',
      strPONbr: poNbrRef.current?.value || '',
      strPOLineNbr: poLineNbrRef.current?.value || '',
      strVin: vinRef.current?.value || '',
      strStockNbr: stockNbrRef.current?.value || '',
      strUpfitterNbr: upfitterNbrRef.current?.value || '',
      strUpfitterStatus: upfitterStatusRef.current?.value || '',
      dtUpfitterETA: upfitterETARef.current?.value ? new Date(upfitterETARef.current.value) : null,
      dtUpfitterComp: upfitterCompRef.current?.value ? new Date(upfitterCompRef.current.value) : null,
      strYR: yearRef.current?.value || '',
      strMake: makeRef.current?.value || '',
      strModel: modelRef.current?.value || '',
      strColor: colorRef.current?.value || '',
      strManStat: manStatRef.current?.value || '',
      dtManETA: dtManETARef.current?.value ? new Date(dtManETARef.current.value) : null,
      dtProdDate: dtProdDateRef.current?.value ? new Date(dtProdDateRef.current.value) : null,
      bDropInd: dropIndRef.current?.checked || false,
      dtRecvDate: recvDateRef.current?.value ? new Date(recvDateRef.current.value) : null,
      strDropLoc: dropLocRef.current?.value || '',
      strDelivStat: delivStatRef.current?.value || '',
      dtDelivDate: delivDateRef.current?.value ? new Date(delivDateRef.current.value) : null,
      dtPDI: dtPDIRef.current?.value ? new Date(dtPDIRef.current.value) : null,
      dtDelivETA: delivETARef.current?.value ? new Date(delivETARef.current.value) : null,
      bBillCDK: billCDKRef.current?.checked || false,
      dtRptDate: rptDateRef.current?.value ? new Date(rptDateRef.current.value) : null,
      bRecall: recallRef.current?.checked || false,
      strDealNbr: dealNbrRef.current?.value || '',
      dtSaleDate: saleDateRef.current?.value ? new Date(saleDateRef.current.value) : null,
      curVehSalePrice: parseCurrency(vehSalePriceRef.current?.value || 0),
      curVehMSRP: parseCurrency(vehMSRPRef.current?.value || 0),
      curVehCOS: parseCurrency(vehCOSRef.current?.value || 0),
      curRebate: parseCurrency(rebateRef.current?.value || 0),
      curWarrSale: parseCurrency(warrSaleRef.current?.value || 0),
      curWarrCost: parseCurrency(warrCostRef.current?.value || 0),
      strWarrType: warrTypeRef.current?.value || '',
      curDoas: parseCurrency(doasRef.current?.value || 0),
      curKeys: parseFloat(keysRef.current?.value || 0),
      currAccess: parseCurrency(accessRef.current?.value || 0),
      curVQ2: parseCurrency(vq2Ref.current?.value || 0),
      curTaxes: parseCurrency(taxesRef.current?.value || 0),
      curUpfitCost: parseCurrency(upfitCostRef.current?.value || 0),
      curTransCost: parseCurrency(transCostRef.current?.value || 0),
      curTTFees: parseCurrency(ttFeesRef.current?.value || 0),
      curVehIncent: parseCurrency(vehIncentRef.current?.value || 0),
      strBillNotes: billNotesRef.current?.value || '',
      manualStatus: manualStatusRef.current?.value || null,
      manualETA: manualETARef.current?.value ? new Date(manualETARef.current.value) : null,
    };

    // Create a copy of the initial vehicle data (the existing data before editing)
    const initialVehicle = (vehicle && vehicle.length === 1) ? vehicle[0] : {};

    // Filter updatedVehicle to only include fields that have changed (are marked as dirty)
    const changedFields = Object.keys(updatedVehicle).reduce((acc, key) => {
      if (dirtyFields[key] && updatedVehicle[key] !== initialVehicle[key]) {
        acc[key] = updatedVehicle[key];
      }
      return acc;
    }, {});

    // If no changes, do nothing
    if (Object.keys(changedFields).length === 0) {
      setLoading(false);
      return;
    }

    try {
      console.log("Changed Fields: ", changedFields);
      if (vehicle && vehicle.length === 1) {
        // Update single vehicle
        await updateData('vehicles', vehicle[0].id, changedFields);
        if (onVehicleUpdated) {
          onVehicleUpdated(changedFields);
        }
      } else if (vehicle && vehicle.length > 1) {
        // Multiple vehicle update (bulk edit)
        await onBulkEditSubmit(changedFields);
      } else {
        // Add new vehicle
        await addData('vehicles', changedFields);
        if (onVehicleAdded) {
          onVehicleAdded();
        }
      }
    } catch (err) {
      console.error("Error adding/updating vehicle:", err);
      setError('Failed to save vehicle');
    }

    setLoading(false);
  };

  // Helper to check if any field has been modified
  const isFormDirty = () => Object.values(dirtyFields).some(field => field === true);

  const renderTooltip = (props) => (
    <Tooltip {...props}>Vehicle not updated</Tooltip>
  );

  return (
    <div className="mt-4" style={{ width: '100%', margin: '0 auto' }}>
      {error && <div className="alert alert-danger">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="salesOrdNbr">
              <FloatingLabel controlId="salesOrdNbr" label="Sales Order Number">
                <Form.Control
                  as="select"
                  value={selectedSalesOrder}
                  onChange={(e) => {
                    const selectedOrder = e.target.value;
                    setSelectedSalesOrder(selectedOrder);
                    // Ensure dirty field update happens after setting the value
                    setDirtyFields((prevState) => ({ ...prevState, strSalesOrdNbr: true }));
                  }}
                  required
                >
                  {salesOrders.map(order => (
                    <option key={order.strSalesOrdNbr} value={order.strSalesOrdNbr}>
                      {order.strSalesOrdNbr}
                    </option>
                  ))}
                </Form.Control>

              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="ordNbr">
              <FloatingLabel controlId="ordNbr" label="Order Number">
                <Form.Control type="text" ref={ordNbrRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strOrdNbr: true }));
                  }} 
                  readOnly={!addVehicle} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="dan">
              <FloatingLabel controlId="dan" label="DAN">
                <Form.Control type="number" ref={danRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strDan: true }));
                  }}
                readOnly={!addVehicle} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="vin">
              <FloatingLabel controlId="vin" label="VIN">
                <Form.Control type="text" ref={vinRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strVin: true }));
                  }} 
                readOnly={!addVehicle} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="stockNbr">
              <FloatingLabel controlId="stockNbr" label="Stock Number">
                <Form.Control type="text" ref={stockNbrRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strStockNbr: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="poNbr">
              <FloatingLabel controlId="poNbr" label="PO Number">
                <Form.Control type="text" ref={poNbrRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strPONbr: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="year">
              <FloatingLabel controlId="year" label="Year">
                <Form.Control type="text" ref={yearRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strYR: true }));
                  }}
                readOnly={!addVehicle} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="make">
              <FloatingLabel controlId="make" label="Make">
                <Form.Control type="text" ref={makeRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strMake: true }));
                  }}
                readOnly={!addVehicle} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="model">
              <FloatingLabel controlId="model" label="Model">
                <Form.Control type="text" ref={modelRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strModel: true }));
                  }}
                readOnly={!addVehicle} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="color">
              <FloatingLabel controlId="color" label="Color">
                <Form.Control type="text" ref={colorRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strColor: true }));
                  }}
                readOnly={!addVehicle} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="keys">
              <FloatingLabel controlId="keys" label="Keys">
                <Form.Control type="number" ref={keysRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, curKeys: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="dropLoc">
              <FloatingLabel controlId="dropLoc" label="Drop Location">
                <Form.Control type="text" ref={dropLocRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strDropLoc: true }));
                  }} 
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="vehMSRP">
              <FloatingLabel controlId="vehMSRP" label="Vehicle MSRP">
                <Form.Control
                  type="text"
                  ref={vehMSRPRef}
                  value={editingVehMSRP ? vehMSRP : formatCurrency(parseCurrency(vehMSRP))}
                  onFocus={() => {
                    setEditingVehMSRP(true);
                    setVehMSRP(parseCurrency(vehMSRP));
                  }}
                  onBlur={() => {
                    setEditingVehMSRP(false);
                    setVehMSRP(formatCurrency(vehMSRP));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setVehMSRP(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curVehMSRP: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="vehSalePrice">
              <FloatingLabel controlId="vehSalePrice" label="Vehicle Sale Price">
                <Form.Control
                  type="text"
                  ref={vehSalePriceRef}
                  value={editingVehSalePrice ? vehSalePrice : formatCurrency(parseCurrency(vehSalePrice))}
                  onFocus={() => {
                    setEditingVehSalePrice(true);
                    setVehSalePrice(parseCurrency(vehSalePrice));
                  }}
                  onBlur={() => {
                    setEditingVehSalePrice(false);
                    setVehSalePrice(formatCurrency(vehSalePrice));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setVehSalePrice(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curVehSalePrice: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="vehCOS">
              <FloatingLabel controlId="vehCOS" label="Vehicle COS">
                <Form.Control
                  type="text"
                  ref={vehCOSRef}
                  value={editingVehCOS ? vehCOS : formatCurrency(parseCurrency(vehCOS))}
                  onFocus={() => {
                    setEditingVehCOS(true);
                    setVehCOS(parseCurrency(vehCOS));
                  }}
                  onBlur={() => {
                    setEditingVehCOS(false);
                    setVehCOS(formatCurrency(vehCOS));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setVehCOS(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curVehCOS: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="warrSale">
              <FloatingLabel controlId="warrSale" label="Warranty Sale Price">
                <Form.Control
                  type="text"
                  ref={warrSaleRef}
                  value={editingWarrSale ? warrSale : formatCurrency(parseCurrency(warrSale))}
                  onFocus={() => {
                    setEditingWarrSale(true);
                    setWarrSale(parseCurrency(warrSale));
                  }}
                  onBlur={() => {
                    setEditingWarrSale(false);
                    setWarrSale(formatCurrency(warrSale));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setWarrSale(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curWarrSale: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="warrCost">
              <FloatingLabel controlId="warrCost" label="Warranty Cost">
                <Form.Control
                  type="text"
                  ref={warrCostRef}
                  value={editingWarrCost ? warrCost : formatCurrency(parseCurrency(warrCost))}
                  onFocus={() => {
                    setEditingWarrCost(true);
                    setWarrCost(parseCurrency(warrCost));
                  }}
                  onBlur={() => {
                    setEditingWarrCost(false);
                    setWarrCost(formatCurrency(warrCost));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setWarrCost(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curWarrCost: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="ttFees">
              <FloatingLabel controlId="ttFees" label="Tag and Title Fees">
                <Form.Control
                  type="text"
                  ref={ttFeesRef}
                  value={editingTTFees ? ttFees : formatCurrency(parseCurrency(ttFees))}
                  onFocus={() => {
                    setEditingTTFees(true);
                    setTTFees(parseCurrency(ttFees));
                  }}
                  onBlur={() => {
                    setEditingTTFees(false);
                    setTTFees(formatCurrency(ttFees));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setTTFees(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curTTFees: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="vq2">
              <FloatingLabel controlId="vq2" label="VQ2">
                <Form.Control
                  type="text"
                  ref={vq2Ref}
                  value={editingVQ2 ? vq2 : formatCurrency(parseCurrency(vq2))}
                  onFocus={() => {
                    setEditingVQ2(true);
                    setVQ2(parseCurrency(vq2));
                  }}
                  onBlur={() => {
                    setEditingVQ2(false);
                    setVQ2(formatCurrency(vq2));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setVQ2(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curVQ2: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="vehIncent">
              <FloatingLabel controlId="vehIncent" label="Vehicle Incentive">
                <Form.Control
                  type="text"
                  ref={vehIncentRef}
                  value={editingVehIncent ? vehIncent : formatCurrency(parseCurrency(vehIncent))}
                  onFocus={() => {
                    setEditingVehIncent(true);
                    setVehIncent(parseCurrency(vehIncent));
                  }}
                  onBlur={() => {
                    setEditingVehIncent(false);
                    setVehIncent(formatCurrency(vehIncent));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setVehIncent(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curVehIncent: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="doas">
              <FloatingLabel controlId="doas" label="DOAS">
                <Form.Control
                  type="text"
                  ref={doasRef}
                  value={editingDoas ? doas : formatCurrency(parseCurrency(doas))}
                  onFocus={() => {
                    setEditingDoas(true);
                    setDoas(parseCurrency(doas));
                  }}
                  onBlur={() => {
                    setEditingDoas(false);
                    setDoas(formatCurrency(doas));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setDoas(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curDoas: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="access">
              <FloatingLabel controlId="access" label="Accessories">
                <Form.Control
                  type="text"
                  ref={accessRef}
                  value={editingAccess ? access : formatCurrency(parseCurrency(access))}
                  onFocus={() => {
                    setEditingAccess(true);
                    setAccess(parseCurrency(access));
                  }}
                  onBlur={() => {
                    setEditingAccess(false);
                    setAccess(formatCurrency(access));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setAccess(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, currAccess: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="upfitCost">
              <FloatingLabel controlId="upfitCost" label="Upfit Cost">
                <Form.Control
                  type="text"
                  ref={upfitCostRef}
                  value={editingUpfitCost ? upfitCost : formatCurrency(parseCurrency(upfitCost))}
                  onFocus={() => {
                    setEditingUpfitCost(true);
                    setUpfitCost(parseCurrency(upfitCost));
                  }}
                  onBlur={() => {
                    setEditingUpfitCost(false);
                    setUpfitCost(formatCurrency(upfitCost));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setUpfitCost(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curUpfitCost: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="transCost">
              <FloatingLabel controlId="transCost" label="Transportation Cost">
                <Form.Control
                  type="text"
                  ref={transCostRef}
                  value={editingTransCost ? transCost : formatCurrency(parseCurrency(transCost))}
                  onFocus={() => {
                    setEditingTransCost(true);
                    setTransCost(parseCurrency(transCost));
                  }}
                  onBlur={() => {
                    setEditingTransCost(false);
                    setTransCost(formatCurrency(transCost));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setTransCost(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curTransCost: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="saleDate">
              <FloatingLabel controlId="saleDate" label="Sale Date">
                <Form.Control type="date" ref={saleDateRef}
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, dtSaleDate: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="ManETA">
              <FloatingLabel controlId="ManETA" label="Manufacturer ETA">
                <Form.Control type="date" ref={dtManETARef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, dtManETA: true }));
                  }}
                />
              </FloatingLabel> 
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="ProdDate">
              <FloatingLabel controlId="ProdDate" label="Production Date">
                <Form.Control type="date" ref={dtProdDateRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, dtProdDate: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="recvDate">
              <FloatingLabel controlId="recvDate" label="Received Date">
                <Form.Control type="date" ref={recvDateRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, dtRecvDate: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="delivDate">
              <FloatingLabel controlId="delivDate" label="Delivery Date">
                <Form.Control type="date" ref={delivDateRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, dtDelivDate: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="statusDate">
              <FloatingLabel controlId="statusDate" label="Last Status Date">
                <Form.Control type="date" ref={statusDateRef}
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, statusDate: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="pdiDate">
              <FloatingLabel controlId="pdiDate" label="PDI Date">
                <Form.Control type="date" ref={dtPDIRef}
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, dtPDI: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="upfitterETA">
              <FloatingLabel controlId="upfitterETA" label="Upfittter ETA">
                <Form.Control type="date" ref={upfitterETARef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, dtUpfitterETA: true }));
                  }}
                /> 
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="upfitterComp">
              <FloatingLabel controlId="upfitterComp" label="Upfitter Complete Date">
                <Form.Control type="date" ref={upfitterCompRef} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, dtUpfitterComp: true }));
                  }}
                /> 
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="upfitterNbr">
              <FloatingLabel controlId="upfitterNbr" label="Upfitter Name">
                <Form.Control
                  as="select"
                  ref={upfitterNbrRef}
                  onChange={(e) => {
                    handleUpfitterChange(e);
                    setDirtyFields(prevState => ({ ...prevState, strUpfitterNbr: true }));
                  }}
                  defaultValue={vehicle && vehicle.length === 1 ? vehicle[0].strUpfitterNbr : ''}
                >
                  <option key="0" value=""></option> {/* Blank option */}
                  {upfitters.map((option, index) => (
                    <option key={index + 1} value={option.strUpfitterNbr}>
                      {option.strUpfitterName}
                    </option>
                  ))}
                </Form.Control>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="upfitterStatus">
              <FloatingLabel controlId="upfitterStatus" label="Upfitter Status">
                <Form.Control
                  as="select"
                  ref={upfitterStatusRef}
                  onChange={(e) => {
                    handleUpfitterStatusChange(e);
                    setDirtyFields(prevState => ({ ...prevState, strUpfitterStatus: true }));
                  }}
                  defaultValue={vehicle && vehicle.length === 1 ? vehicle[0].strUpfitterStatus : ''}
                >
                  <option key="0" value=""></option> {/* Blank option */}
                  {upfitterStatusOptions.map((option, index) => (
                    <option key={index + 1} value={option.strManStat}>
                      {option.strFactStatus}
                    </option>
                  ))}
                </Form.Control>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="warrType">
              <FloatingLabel controlId="warrType" label="Warranty Type">
                <Form.Control type="text" ref={warrTypeRef}
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strWarrType: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Form.Group className="mb-3" controlId="billNotes">
              <FloatingLabel controlId="billNotes" label="Bill Notes">
                <Form.Control as="textarea" ref={billNotesRef} style={{ height: '100px' }} 
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strBillNotes: true })); 
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="manualStatus">
              <FloatingLabel controlId="manualStatus" label="Manual Status">
                <Form.Control
                  as="select"
                  ref={manualStatusRef}
                  onChange={(e) => {
                    setManualStatus(e.target.value)
                    setDirtyFields(prevState => ({ ...prevState, manualStatus: true })); 
                  }}
                  defaultValue={manualStatus}
                >
                  <option value="" style={{ backgroundColor: "white" }}>Select Status</option>
                  <option value="Ordered" style={{ backgroundColor: "white" }}>Ordered</option>
                  <option value="Scheduled" style={{ backgroundColor: "saddlebrown" }}>Scheduled</option>
                  <option value="Produced" style={{ backgroundColor: "saddlebrown" }}>Produced</option>
                  <option value="In Transit" style={{ backgroundColor: "saddlebrown" }}>In Transit</option>
                  <option value="On the Ground" style={{ backgroundColor: "orange" }}>On the Ground</option>
                  <option value="Customer Signed" style={{ backgroundColor: "yellow" }}>Customer Signed</option>
                  <option value="At Upfitter" style={{ backgroundColor: "yellow" }}>At Upfitter</option>
                  <option value="Delivered" style={{ backgroundColor: "green" }}>Delivered</option>
                </Form.Control>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="manualETA">
              <FloatingLabel controlId="manualETA" label="Manual ETA">
                <Form.Control
                  type="date"
                  ref={manualETARef}
                  onChange={(e) => {
                    setManualETA(new Date(e.target.value))
                    setDirtyFields(prevState => ({ ...prevState, manualETA: true }));
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="manStat">
              <FloatingLabel controlId="manStat" label="Manufacturer Status">
                <Form.Control type="text" ref={manStatRef}
                  onChange={(e) => {
                    setDirtyFields(prevState => ({ ...prevState, strManStat: true }));
                  }}
                  readOnly />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="taxes">
              <FloatingLabel controlId="taxes" label="Taxes">
                <Form.Control
                  type="text"
                  ref={taxesRef}
                  value={editingTaxes ? taxes : formatCurrency(parseCurrency(taxes))}
                  onFocus={() => {
                    setEditingTaxes(true);
                    setTaxes(parseCurrency(taxes));
                  }}
                  onBlur={() => {
                    setEditingTaxes(false);
                    setTaxes(formatCurrency(taxes));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setTaxes(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curTaxes: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="rebate">
              <FloatingLabel controlId="vehCOS" label="Rebate">
                <Form.Control
                  type="text"
                  ref={rebateRef}
                  value={editingRebate ? rebate : formatCurrency(parseCurrency(rebate))}
                  onFocus={() => {
                    setEditingRebate(true);
                    setRebate(parseCurrency(rebate));
                  }}
                  onBlur={() => {
                    setEditingRebate(false);
                    setRebate(formatCurrency(rebate));
                  }}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,]/g, ''); // Remove $ and commas
                    if (!isNaN(rawValue)) {
                      setRebate(rawValue)
                      setDirtyFields(prevState => ({ ...prevState, curRebate: true }));
                    }
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}>
            <OverlayTrigger
              placement="top"
              overlay={!isFormDirty() ? renderTooltip : <></>}
            >
              <div style={{ display: 'inline-block' }}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={loading || !isFormDirty()}
                  style={{ pointerEvents: isFormDirty() ? 'auto' : 'none' }} // Ensures tooltip shows on hover over the wrapper
                >
                  Submit
                </Button>
              </div>
            </OverlayTrigger>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="dropInd">
              <Form.Check type="checkbox" label="Drop Indicator" ref={dropIndRef} 
                onChange={(e) => {
                  setDirtyFields(prevState => ({ ...prevState, bDropInd: true }));
                }} 
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="billCDK">
              <Form.Check type="checkbox" label="Billed CDK" ref={billCDKRef} 
                onChange={(e) => {
                  setDirtyFields(prevState => ({ ...prevState, bBillCDK: true }));
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={2}>
            <Form.Group className="mb-3" controlId="recall">
              <Form.Check type="checkbox" label="Recall" ref={recallRef} 
                onChange={(e) => {
                  setDirtyFields(prevState => ({ ...prevState, bRecall: true }));
                }} bRecall
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {teamsLoading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <div>{submitMessage}</div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default VehicleForm;
