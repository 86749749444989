import React, { useRef, useState, useEffect } from 'react';
import { Button, Form, FloatingLabel, Spinner } from 'react-bootstrap'; // Import Spinner
import { httpsCallable } from 'firebase/functions';
import { functions, db } from '../firebaseConfig';
import { useFirestore } from '../contexts/FirestoreContext';
import { doc, updateDoc } from 'firebase/firestore';
import Select from 'react-select';


function UserForm({ onClose, user, userRole }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Manage loading state
  const [customers, setCustomers] = useState([]);
  const [upfitters, setUpfitters] = useState([]);
  const [selectedUpfitter, setSelectedUpfitter] = useState('None');
  const [selectedRole, setSelectedRole] = useState('Customer');
  const { getData } = useFirestore();
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [mustChangePassword, setMustChangePassword] = useState(false);

  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  console.log("USER ROLE: ", userRole)

  useEffect(() => {
    const fetchData = async () => {
      const customerData = await getData('customers');
      const upfitterData = await getData('Upfitters');
      setCustomers(customerData);
      setUpfitters(upfitterData);

      if (user) {
        if (nameRef.current) nameRef.current.value = user.name || '';
        if (emailRef.current) emailRef.current.value = user.email || '';
        setSelectedCustomers(user.customers || []);
        setSelectedUpfitter(user.upfitter || 'None');
        setSelectedRole(user.role || 'Customer');
      }
    };
    fetchData();
  }, [getData, user]);

  async function handleSubmit(e) {
    e.preventDefault();
    setError('');
    setLoading(true); // Set loading to true at the start

    try {
      if (user) {
        // Edit existing user
        const updatedUser = {
          ...user,
          name: nameRef.current.value,
          email: emailRef.current.value,
          customers: selectedCustomers,
          upfitter: selectedUpfitter,
          role: selectedRole,
        };

        // Update user in Firestore
        const userRef = doc(db, 'users', user.id);
        await updateDoc(userRef, updatedUser);

        // Prepare data for the role and password update
        let updateData = {};
        const newPassword = passwordRef.current?.value;
        if (mustChangePassword) updateData.mustChangePassword = true;

        // Check if password has changed
        if (newPassword) {
          updateData.password = newPassword;
        }

        // Check if role has changed
        if (user.role !== selectedRole) {
          updateData.role = selectedRole;
        }

        // If there's anything to update (role or password), call the function
        if (Object.keys(updateData).length > 0) {
          const updateUser = httpsCallable(functions, 'updateUser');
          await updateUser({
            uid: user.id,
            ...updateData, // Send role and/or password in the update
          });
        }

      } else {
        // Add new user
        const createUser = httpsCallable(functions, 'createUser');
        await createUser({
          email: emailRef.current.value,
          password: passwordRef.current.value,
          mustChangePassword,
          name: nameRef.current.value,
          customers: selectedCustomers,
          upfitter: selectedUpfitter,
          role: selectedRole,
        });
      }

      onClose();
    } catch (error) {
      setError('Failed to save user: ' + error.message);
      console.error("Error creating/updating user: ", error);
    }

    setLoading(false); // Set loading to false after the process is complete
  }

  const handleMustChangePassword = (e) => {
    setMustChangePassword(e.target.checked);
  };

  const handleCustomerChange = (selectedOptions) => {
    setSelectedCustomers(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleUpfitterChange = (e) => {
    setSelectedUpfitter(e.target.value);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  return (
    <div className="card mt-4">
      <div className="card-body">
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit}>
          <fieldset disabled={loading}> {/* Disable the form when loading */}
            <Form.Group className="mb-3" controlId="name">
              <FloatingLabel controlId="floatingName" label="Name">
                <Form.Control type="text" ref={nameRef} required />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <FloatingLabel controlId="floatingEmail" label="Email">
                <Form.Control type="email" ref={emailRef} required />
              </FloatingLabel>
            </Form.Group>
            {!user && (
              <Form.Group className="mb-3" controlId="password">
                <FloatingLabel controlId="floatingPassword" label="Password">
                  <Form.Control type="password" ref={passwordRef} required />
                </FloatingLabel>
                <Form.Check
                  type="checkbox"
                  label="User Must Change Password on Next Login"
                  checked={mustChangePassword}
                  onChange={handleMustChangePassword}
                />
              </Form.Group>
            )}
            {user && (
              <Form.Group className="mb-3" controlId="newPassword">
                <FloatingLabel controlId="floatingNewPassword" label="New Password (Optional)">
                  <Form.Control type="password" ref={passwordRef} />
                </FloatingLabel>
                <Form.Check
                  type="checkbox"
                  label="User Must Change Password on Next Login"
                  checked={mustChangePassword}
                  onChange={handleMustChangePassword}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="customer">
              <Form.Label>Customers</Form.Label>
              <Select
                isMulti
                value={customers
                  .filter(cust => selectedCustomers.includes(cust.strCustNbr))
                  .map(cust => ({ value: cust.strCustNbr, label: cust.strCustName }))}
                onChange={handleCustomerChange}
                options={customers.map(cust => ({ value: cust.strCustNbr, label: cust.strCustName }))}
                styles={{
                  control: (provided) => ({ ...provided, zIndex: 9999 }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="upfitter">
              <FloatingLabel controlId="floatingUpfitter" label="Upfitter">
                <Form.Select value={selectedUpfitter} onChange={handleUpfitterChange}>
                  {upfitters.map(upfitter => (
                    <option key={upfitter.strUpfitterNbr} value={upfitter.strUpfitterNbr}>
                      {upfitter.strUpfitterName}
                    </option>
                  ))}
                  <option value="None">None</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="role">
              <FloatingLabel controlId="floatingRole" label="Role" >
                <Form.Select value={selectedRole} onChange={handleRoleChange} disabled={(userRole === 'BDC')}>
                  <option value="Admin">Admin</option>
                  <option value="PDI">PDI</option>
                  <option value="Sales Manager">Sales Manager</option>
                  <option value="Sales Rep">Sales Rep</option>
                  <option value="BDC">BDC</option>
                  <option value="Customer">Customer</option>
                  <option value="Upfitter">Upfitter</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}
                  { user ? 'Updating...' : 'Adding...' }
                </>
              ) : user ? 'Update User' : 'Add User'}
            </Button>
          </fieldset>
        </Form>
      </div>
    </div>
  );
}

export default UserForm;
